import logoBlack from "./logo.png"
import logoWhite from "./logo_white.png"
import { useEffect, useState } from "react"
import "./Logo.scss"
const Logo = ({style, logo}) => {
    const [src, setSrc] = useState("");
    useEffect(()=>{
        switch(logo){
            case "white":
                setSrc(logoWhite);
                break;
            case "black":
                setSrc(logoBlack);
                break;
            default:
                setSrc(logoBlack)

        }
    }, [logo]);
    return(
        <div className="Elm-Logo" style={style}>
            <img src={src} alt="logo" />
        </div>
    )
}
export default Logo