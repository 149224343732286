import React from "react";
import "./Modal.scss";
import Portal from "Portal";
import CloseIcon from "./close.svg";

const Modal = (props) => {
    const {children, toggle, active, title, titleColor} = props;
    return(
        <Portal>
            {active && <div className="modal-wrapper">
                <div className="modal-window">
                    <div className="modal-title-container">
                        <h2 style={{color: titleColor ? titleColor : "#FFF"}}>{title}</h2>
                        <button className="modal-btn-close" onClick={toggle}><img src={CloseIcon} alt="close-icon" /></button>
                    </div>
                    <div>
                        <div>{children}</div>
                    </div>
                </div>
            </div>}
        </Portal>
    )
}
export default Modal