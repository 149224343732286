import { useState } from "react";
import Logo from "elements/Logo";
import "./SmashStages.scss";
import battlefield from "./battlefield.jpg";
import smallBattlefield from "./small-battlefield.jpeg";
import finalDestination from "./final-destination.jpeg";
import pokemonStadium2 from "./pokemon-stadium-2.jpg";
import hollowBastion from "./hollow-bastion.jpg";
import kalos from "./kalos.jpg";
import smashville from "./smashville.jpg";
import townAndCity from "./town-and-city.jpg"
import Button from "elements/Button";

const SmashStages = () => {
    const [stages, setStages] = useState([
        {
            id: 0,
            name: "Battlefield",
            img: battlefield,
            type: "starter",
            isActive: false
        },
        {
            id: 1,
            name: "Small Battlefield",
            img: smallBattlefield,
            type: "starter",
            isActive: false
        },
        {
            id: 2,
            name: "Final Destination",
            img: finalDestination,
            type: "starter",
            isActive: false
        },
        {
            id: 3,
            name: "Pokémon Stadium 2",
            img: pokemonStadium2,
            type: "starter",
            isActive: false
        },
        {
            id: 4,
            name: "Hollow Bastion",
            img: hollowBastion,
            type: "starter",
            isActive: false
        },
        {
            id: 5,
            name: "Kalos",
            img: kalos,
            type: "counterpick",
            isActive: false
        },
        {
            id: 6,
            name: "Smashville",
            img: smashville,
            type: "counterpick",
            isActive: false
        },
        {
            id: 7,
            name: "Town and City",
            img: townAndCity,
            type: "counterpick",
            isActive: false
        },
    ])


    const activeStarter = () => {
        const s = [...stages];
        for(let i = 0 ; i < s.length ; i++){
            const x = s[i];
            x.isActive = x.type === "starter";
            s[i] = x;
        }
        setStages(s)
    }
    const activeAll = () => {
        const s = [...stages];
        for(let i = 0 ; i < s.length ; i++){
            const x = s[i];
            x.isActive = true;
            s[i] = x;
        }
        setStages(s)
    }

    return(
        <div className="SmashStages">
            <h1>Smash Stage Selector</h1>
            <Logo/>
            <div style={{textAlign: "center"}}>
                <Button onClick={activeStarter}>
                    Starter
                </Button>
                <Button onClick={activeAll}>
                    CounterPick
                </Button>
            </div>
            <div className="SmashStages-Stages-Container">
                <div className="SmashStages-Stages">
                    {stages.map((x, index)=>( <>
                        <Stage info={x} key={index} setStages={setStages} all={stages} />
                    </> ))}
                </div>
            </div>
        </div>
    )
}
const Stage = ({info, setStages, all}) => {

    const changeStatus = () => {
        const s = [...all];
        const i = s[info.id]
        i.isActive = !info.isActive
        s[info.id] = i;
        setStages(s);
    }

    return(
        <div className="SmashStages-Stages-Stage">
            <button onClick={changeStatus}>
                <div className={info.isActive ? "SmashStages-Stages-Stage-Elm active" : "SmashStages-Stages-Stage-Elm"}>
                    <img src={info.img} alt={info.name} />
                    <h5>{info.name}</h5>
                </div>
            </button>
        </div>
    )
}
export default SmashStages