import { useEffect, useState } from "react"
import { Link, useParams, redirect, useNavigate} from "react-router-dom";
import { API } from "const/api";
import Button from "elements/Button";
import InputText from "elements/InputText"
import Logo from "elements/Logo";
import axios from "axios";
import AuthLayout from "views/layouts/AuthLayout"
import { EXTERNAL_LINKS, LINKS } from "const/routes";

const SignUp = () => {
    const [form, setForm] = useState({
        nickname: "",
        fullname: "",
        email: "",
        password: "",
        password_confirmation: ""
    });
    const [msg, setMsg] = useState("");
    const [validateUsernameMsg, setValidateUsernameMsg] = useState("");
    const [registerReady, setRegisterReady] = useState(false);

    const params = new URLSearchParams(window.location.search);
    let r = null
    const navigate = useNavigate();

    useEffect(()=>{
        r = params.get("r");
    }, [params])

    const handleInputChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const userSignUp = () => {
        let validation = true;

        if (form.nickname === "" || form.fullname === "" || form.email === "" || form.password === "" || form.password_confirmation === ""){
            validation = false;
            setMsg("Uno o más campos vacíos, por favor complete la información");
        }else if (form.password !== form.password_confirmation){
            validation = false;
            setMsg("Error al confirmar contraseña");
        }

        if(validation){
            const formData = new FormData();
            formData.append("nickname", form.nickname);
            formData.append("name", form.fullname);
            formData.append("email", form.email);
            formData.append("password", form.password);
            formData.append("password_confirmation", form.password_confirmation);
            axios.post(API.AUTH.SIGN_UP, formData)
            .then(response=>{
                localStorage.setItem("access_token", response.data.body.access_token)
                localStorage.setItem("token_type", response.data.body.token_type)
                navi();
            })
            .catch(error=>console.error(error));
        }
    }
    const verifyUsername = () => {
        axios.get(API.AUTH.VERIFY_USERNAME.replace("{username}", form.nickname))
        .then(response=>{
            console.log(response)
            setValidateUsernameMsg(response.data.body.message);
        }).catch(error=>console.error(error))
    }

    useEffect(()=>{ navi() }, [localStorage, r])
    
    const navi = () => {
        if(localStorage.getItem("access_token") !== null){
            if(r === null){
                navigate("/");
            }else{
                r+="t="+localStorage.getItem("access_token");
                console.log(r);
                window.location.replace(r);
            }
        }
    }

    return(
        <div>
            <AuthLayout>
                <Logo />
                <h1 style={{textAlign:"center"}}>Registro</h1>
                {msg !== "" && <p>{msg}</p>}
                <div>
                    <InputText
                        label="Nickname"
                        name="nickname"
                        value={form.nickname}
                        setValue={handleInputChange}
                        msg={validateUsernameMsg}
                        onBlur={verifyUsername}
                    />
                    <InputText
                        label="Nombre Completo"
                        name="fullname"
                        value={form.fullname}
                        setValue={handleInputChange}
                    />
                    <InputText
                        label="Email"
                        name="email"
                        value={form.email}
                        setValue={handleInputChange}
                        type="email"
                    />
                    <InputText
                        label="Contraseña"
                        name="password"
                        value={form.password}
                        setValue={handleInputChange}
                        type="password"
                    />
                    <InputText
                        label="Confirmar Contraseña"
                        name="password_confirmation"
                        value={form.password_confirmation}
                        setValue={handleInputChange}
                        type="password"
                    />
                    <div style={{width:"100%", display:"flex", marginTop:"24px"}}>
                        <div style={{margin:"auto"}}>
                            <Button onClick={userSignUp}>
                                Registrarse
                            </Button>
                        </div>
                    </div>
                    <div>
                        ¿Ya tienes cuenta? <Link to={LINKS.AUTH.SIGN_IN}>Inicia Sesión</Link>
                    </div>
                </div>
            </AuthLayout>
        </div>
    )
}
export default SignUp