import React, {useState} from "react"
import "./InputText.scss"

const InputText = ({label, name, value, setValue, type, msg, msgType, onBlur}) =>{
    const [style, setStyle] = useState({});
    
    const inputFocus = () =>{
        setStyle({
            left: "-150px",
            maxWidth: "150px"
        });
    }
    const inputBlur = () =>{
        if(value === ""){
            setStyle({
                left: "0px",
                maxWidth: "250px"
            });
        }
        if(onBlur) onBlur()
    }

    return(
        <div className="Elm-InputText">
            <div className="Elm-InputText-Container">
                <div className="Elm-InputText-Content">
                    <div className="Elm-InputText-Label-Container" style={style}>
                        <label
                            className="Elm-InputText-Label"
                            for={name}
                            >
                            {label}
                        </label>
                    </div>
                    <div className="Elm-InputText-Input-Container">
                        <input
                            className="Elm-InputText-Input"
                            id={name}
                            onFocus={inputFocus}
                            onBlur={inputBlur}
                            name={name}
                            value={value}
                            onChange={setValue}
                            type={type ? type : "text"}
                            />
                        <p className="Elm-InputText-Input-Msg">
                            {msg}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InputText