const LINKS = {
    HOME: "/",
    AUTH:{
        SIGN_UP: "/sign-up",
        SIGN_IN: "/sign-in"
    },
    PROFILE: "/profile"
}
const EXTERNAL_LINKS = {
    SHUMI:{
        TOURNAMENT: "https://tournament.shumigames.com"
    }
}
export {LINKS, EXTERNAL_LINKS}