import React, { useState } from "react";
import "./Button.scss";
import { Link } from "react-router-dom";

const Button = ({onClick, children, styled, href}) => {
    let className = "ElmButton";
    switch(styled){
        case "black":
            className = "ElmButton Black"
            break;
        default:
            className = "ElmButton"
    }

    return(
        <>
        {href ? 
        <Link to={href} className={className}>
            {children}
        </Link>
            :
        <button className={className}
            onClick={onClick}
        >
            {children}
        </button>
        }
        </>
    )
}

export default Button