import "./Profile.scss";
import axios from "axios";
import { API } from "const/api";
import { LINKS } from "const/routes";
import Button from "elements/Button"
import { useEffect, useState } from "react"
import BasicLayout from "views/layouts/BasicLayout"

const Profile = () => {
    const [me, setMe] = useState({});
    useEffect(()=>{
        const token = localStorage.getItem("token_type") + " " + localStorage.getItem("access_token");
        axios.get(API.ME, {headers:{Authorization: token}})
        .then(response=>{
            console.log(response.data.body)
            setMe(response.data.body);
        }).catch(error=>console.error(error))
    }, []);

    const logout = () => {
        localStorage.clear();
        window.location.replace(LINKS.HOME)
    }

    return(
        <BasicLayout>
            <div className="Profile">
                <div className="Profile-UserInfo">
                    <div>
                        <ul>
                            <li>
                                Nickname: {me.nickname}
                            </li>
                            <li>
                                Display Nickname: {me.display_nickname}
                            </li>
                            <li>
                                Correo Electrónico: {me.email}
                            </li>
                            <li>
                                Nombre real: {me.name}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div style={{marginTop:"24px"}}>
                <Button onClick={logout}>Cerrar Sesión</Button>
            </div>
        </BasicLayout>
    )
}
export default Profile