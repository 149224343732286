import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/pages/Home"
import "./components/FontAwesomeIcon"
import {LINKS} from "const/routes"
import SignUp from 'views/pages/Auth/SignUp';
import Testing from 'views/pages/Testing';
import SignIn from 'views/pages/Auth/SignIn';
import SmashStages from 'views/pages/SmashStages';
import Profile from 'views/pages/Profile';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/1478523690" element={<Testing />} />
                <Route path={LINKS.AUTH.SIGN_UP} element={<SignUp />} />
                <Route path={LINKS.AUTH.SIGN_IN} element={<SignIn />} />
                <Route path={LINKS.PROFILE} element={<Profile />} />
                <Route path="/smash-stages" element={<SmashStages />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
