import React, { useEffect, useState } from "react"
import Logo from "elements/Logo";
import "./Home.scss";
import { EXTERNAL_LINKS, LINKS } from "const/routes";
import Modal from "components/Modal";
import InputText from "elements/InputText";
import Button from "elements/Button";
import axios from "axios";
import { API } from "const/api";

const Home = () =>{
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [suscribeEmail, setSuscribeEmail] = useState("")

    return(
        <div className="Home">
            <header className="Home-Header">
                <div className="Home-Header-Content">
                    <div className="Home-Header-Logo">
                        <Logo logo="white" />
                    </div>
                    <div className="Home-Header-Nav">
                        <LoginButton />
                    </div>
                </div>
            </header>
            <div className="HomeC">
                <div className="Home-Container">
                    <div className="Home-Content">
                        <div className="Home-Section-Container">
                            <div className="Home-Section-1">
                                <a href="#" className="Home-Section-Link">
                                    <div className="Home-Section-Content">
                                        <h3>Próximamente</h3>
                                    </div>
                                </a>
                            </div>
                            <div className="Home-Section-2">
                                <a href="#" className="Home-Section-Link">
                                    <div className="Home-Section-Content">
                                        <h3>Próximamente</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="Home-Section-Container">
                            <div className="Home-Section-3">
                                <a href="#" className="Home-Section-Link">
                                    <div className="Home-Section-Content">
                                        <h3>Próximamente</h3>
                                    </div>
                                </a>
                            </div>
                            <div className="Home-Section-4">
                                <a href="#" className="Home-Section-Link">
                                    <div className="Home-Section-Content">
                                        <h3>Próximamente</h3>
                                    </div>
                                </a>
                            </div>
                            <div className="Home-Section-5">
                                <a href="#" className="Home-Section-Link">
                                    <div className="Home-Section-Content">
                                        <h3>Próximamente</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal active={modalIsVisible} title={"Información"}>
                <div className="SuscribeModal">
                    <p>
                        Estamos trabajando para ofrecerte la mejor experiencia
                    </p>
                    <p style={{marginTop:"8px"}}>
                        Si quieres estar al tanto de nuestras novedades, puedes dejar tu correo electrónico y te mantendremos al día.
                    </p>
                    <div style={{display:"flex", paddingTop:"24px"}}>
                        <div style={{maxWidth:"350px", margin:"auto"}}>
                            <InputText
                                label="Email"
                                name="email"
                                value={suscribeEmail}
                                setValue={(e)=>setSuscribeEmail(e.target.value)}
                                type="email"
                            />
                        </div>
                    </div>
                    <div style={{display: "flex", marginTop:"16px"}}>
                        <div style={{marginLeft:"auto"}}>
                            <Button>
                                ¡Quiero estar al día!
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const LoginButton = () => {
    const [isSesstion, setIsSession] = useState(false);
    const [displayNickname, setDisplayNickname] = useState("");

    useEffect(()=>{
        if(localStorage.getItem("access_token") !== null){
            setIsSession(true)
            getUserInfo();
        }else{
            setIsSession(false);
        }
    }, [])

    const getUserInfo = () => {
        const token = localStorage.getItem("token_type") + " " + localStorage.getItem("access_token");
        axios.get(API.ME, {headers:{Authorization: token}})
        .then(response=>{
            setDisplayNickname(response.data.body.display_nickname)
        }).catch()
    }

    return(
        <>
        {isSesstion ? 
        <div>
            <Button href={LINKS.PROFILE}>
                ¡Hola!, {displayNickname}
            </Button>
        </div>
        :
        <div>
            <Button href={LINKS.AUTH.SIGN_IN}>
                Iniciar Sesión
            </Button>
        </div>
        }
        </>
    )
}

export default Home