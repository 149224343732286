import axios from "axios";
import { API } from "const/api";
import { LINKS } from "const/routes";
import Button from "elements/Button";
import InputText from "elements/InputText"
import Logo from "elements/Logo";
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthLayout from "views/layouts/AuthLayout"

const SignIn = () => {
    const [form, setForm] = useState({ email: "", password: "" });
    const [msg, setMsg] = useState("");
    

    const params = new URLSearchParams(window.location.search);
    let r = null
    const navigate = useNavigate();
    
    useEffect(()=>{
        r = params.get("r");
    }, [params])

    const handleInputChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const userSignUp = () => {
        let validation = true;

        if (form.email === "" || form.password === ""){
            validation = false;
            setMsg("Uno o más campos vacíos, por favor complete la información");
        }

        if(validation){
            const formData = new FormData();
            formData.append("email", form.email);
            formData.append("password", form.password);
    
            axios.post(API.AUTH.SIGN_IN, formData)
            .then(response=>{
                localStorage.setItem("access_token", response.data.body.access_token)
                localStorage.setItem("token_type", response.data.body.token_type)
                navi();
            })
            .catch(error=>console.error(error));
        }
    }

    useEffect(()=>{ navi() }, [localStorage, r])

    const navi = () => {
        if(localStorage.getItem("access_token") !== null){
            if(r === null){
                navigate("/");
            }else{
                r+="t="+localStorage.getItem("access_token");
                console.log(r);
                window.location.replace(r);
            }
        }
    }
    
    return(
        <div>
            <AuthLayout>
                <Logo />
                <h1 style={{textAlign:"center"}}>Iniciar Sesión</h1>
                {msg !== "" && <p>{msg}</p>}
                <div>
                    <InputText
                        label="Email"
                        name="email"
                        value={form.email}
                        setValue={handleInputChange}
                    />
                    <InputText
                        label="Contraseña"
                        name="password"
                        value={form.password}
                        setValue={handleInputChange}
                        type="password"
                    />
                    <div style={{width:"100%", display:"flex", marginTop:"24px"}}>
                        <div style={{margin:"auto"}}>
                            <Button onClick={userSignUp}>
                                Iniciar Sesión
                            </Button>
                        </div>
                    </div>
                    <div>
                        ¿No tienes cuenta? <Link to={LINKS.AUTH.SIGN_UP}>Crea una</Link>
                    </div>
                </div>
            </AuthLayout>
        </div>
    )
}
export default SignIn