import "./BasicLayout.scss";
const BasicLayout = ({children}) => {
    return(
        <div className="BasicLayout">
            <div className="BasicLayout-Content">
                {children}
            </div>
        </div>
    )
}
export default BasicLayout