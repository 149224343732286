import { useEffect, useRef, useState } from "react"
import track_1 from "./1.mp3";
import track_2 from "./2.mp3";
import bg_working from "./working.jpeg";
import bg_rest from "./rest.jpeg";

const Testing = () => {
    const [s, setS] = useState(0);
    const [isWorking, setIsWorking] = useState(true)
    const [bg, setBg] = useState();
    const [bonus, setBonus] = useState(0);
    const [hora, setHora] = useState(0);

    const audioRef1 = useRef();
    const audioRef2 = useRef();

    useEffect(()=>{
        const interval = setInterval(()=>{
            let x = s - 1;
            const h = new Date();
            if(s <= 0){
                x = !isWorking ? ((60 * 52) - bonus) : (60 * 17);
                if(x === 0) setBonus(0);
                setIsWorking(!isWorking);
            }
            setS(x);
            console.log(h);
            setHora(h.getHours());
        }, 1000)
        return ()=> clearInterval(interval)
    }, [s, isWorking, bonus]);

    useEffect(()=>{
        if(isWorking){
            audioRef1.current.play();
            setBg(bg_working);
            setBonus(prev=>prev+1);
        }else{
            audioRef2.current.play();
            setBg(bg_rest);
        }
    }, [isWorking])
    return(
        <div
            style={{
                backgroundImage: "url("+bg+")",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                minHeight: "100vh",
                width:"100%"
            }}
        >
            <h1>
                {s}
            </h1>
            <h2>Status: {isWorking ? "Working" : "Rest"}</h2>
            <audio controls ref={audioRef1}>
                <source src={track_1} type="audio/mp3" />
                Your browser does not support the audio element.
            </audio>
            <audio controls ref={audioRef2}>
                <source src={track_2} type="audio/mp3" />
                Your browser does not support the audio element.
            </audio>
            <h3>Hora: {hora}</h3>
        </div>
    )
}
export default Testing