import "./AuthLayout.scss";
const AuthLayout = ({children}) => {
    return(
        <div className="AuthLayout">
            <div className="AuthLayout-Content">
                {children}
            </div>
        </div>
    )
}
export default AuthLayout