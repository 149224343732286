const API_BASE = window.location.hostname === "localhost" ? "http://127.0.0.1:8000/" : "https://elback.shumigames.com/"
const VERSION = "v1"
const API_URL = API_BASE + "api/" + VERSION + "/";
const API = {
    AUTH:{
        SIGN_UP: API_URL + "auth/sign-up",
        SIGN_IN: API_URL + "auth/sign-in",
        VERIFY_USERNAME: API_URL + "auth/verify-username/{username}",
    },
    ME: API_URL + "me"
}
export {API}